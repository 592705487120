<template>
  <div class="news-page">
    <div class="safe-area-banner">
      <div class="fs32">行愿资讯</div>
      <el-input
        v-model="keyword"
        class="search-input"
        placeholder="请输入关键词"
        @keyup.enter.native="handleSearch"
      >
        <i slot="suffix" class="el-icon-search" @click="handleSearch"></i>
      </el-input>
    </div>
    <no-data v-if="newsList.length === 0"/>
    <div v-else class="news-grid">
      <news-item v-for="news in newsList" :key="news.id" :news="news"/>
    </div>
    <el-pagination
      class="pagination"
      background
      :current-page="pageIndex"
      layout="prev, pager, next"
      :page-size="9"
      :total="total"
      hide-on-single-page
      @current-change="handlePageChange"
    />
  </div>
</template>

<script>
  import NewsItem from "@/components/Common/NewsItem";
  import NoData from "@/components/Common/NoData";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "News",
    components: { NoData, NewsItem },
    data() {
      return {
        total: 0,
        pageIndex: 1,
        newsList: [],
        keyword: ''
      }
    },
    watch: {
      '$route.query.id': {
        immediate: true,
        handler() {
          this.keyword = ''
          this.fetchNewsList()
        }
      }
    },
    methods: {
      async fetchNewsList() {
        const params = { pageNo: this.pageIndex, pageSize: 9, keyword: this.keyword }
        const { result: { records, total } } = await emeiApi.getNewsList(params)
        this.newsList = records
        this.total = total
      },
      handleSearch() {
        this.pageIndex = 1
        this.fetchNewsList()
      },
      handlePageChange(index) {
        this.pageIndex = index
        this.fetchNewsList()
      }
    }
  }
</script>

<style scoped lang="scss">
  .news-page {
    padding-bottom: 40px;
    .safe-area-banner {
      background: url("~@/assets/images/news_banner.jpg") center;
      .search-input {
        width: 489px;
        margin-top: 40px;
      }
    }
    .news-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(auto-fill, 1fr);
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      margin-top: 30px;
    }
    .pagination {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }
</style>

<style lang="scss">
  .news-page {
    .search-input {
      .el-input__inner {
        border-radius: 0;
        padding-right: 60px;
      }
      .el-input__suffix {
        right: 20px;
        i {
          font-size: 20px;
          color: #333333;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
    .el-pagination.is-background {
      .el-pager li, .btn-prev, .btn-next {
        font-weight: normal;
        background-color: transparent;
        border: 1px solid $color-border;
        &:hover {
          color: $color-primary;
          border-color: $color-primary;
        }
      }
      .btn-prev:disabled, .btn-next:disabled {
        &:hover {
          border: 1px solid #E1E2E6;
          color: #C0C4CC;
        }
      }
    }
  }
</style>
