<template>
  <div class="no-data flex-center">
    <img v-if="showImg" src="~@/assets/svgs/no_data.svg" alt="">
    <div class="tip">暂无内容</div>
  </div>
</template>

<script>
  export default {
    name: "NoData",
    props: {
      showImg: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .no-data {
    padding: 40px 0;
    flex-direction: column;
    img {
      width: 120px;
      height: 120px;
    }
    .tip {
      color: $font-color-3;
    }
  }
</style>
