<template>
  <div class="news-page">
    <div class="safe-area-banner">
      <div class="fs32">信息公示</div>
    </div>
    <no-data v-if="noticeList.length === 0"/>
    <div v-else class="notice-wrapper">
      <div
        v-for="item in noticeList"
        :key="item.id"
        class="notice-item space-between vertical-center fs16"
      >
        <div class="file-name">{{ item.fileName }}</div>
        <div class="file-size">{{ item.fileSize }}</div>
        <a class="download-link" :href="item.url" target="_blank">查看</a>
        <a class="download-link" :href="getDownloadUrl(item.id)">下载</a>
      </div>
    </div>
    <el-pagination
      class="pagination"
      background
      :current-page="pageIndex"
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="total"
      hide-on-single-page
      @current-change="handlePageChange"
    />
  </div>
</template>

<script>
  import NoData from "@/components/Common/NoData";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "Notice",
    components: { NoData },
    data() {
      return {
        total: 0,
        pageIndex: 1,
        pageSize: 15,
        noticeList: [],
        keyword: ''
      }
    },
    watch: {
      '$route.query.id': {
        immediate: true,
        handler() {
          this.keyword = ''
          this.fetchNewsList()
        }
      }
    },
    methods: {
      async fetchNewsList() {
        const params = { pageNo: this.pageIndex, pageSize: this.pageSize }
        const { result: { records, total } } = await emeiApi.getNoticeList(params)
        this.noticeList = records
        this.total = total
      },
      handleSearch() {
        this.pageIndex = 1
        this.fetchNewsList()
      },
      handlePageChange(index) {
        this.pageIndex = index
        this.fetchNewsList()
      },
      getDownloadUrl(id) {
        return `${process.env.VUE_APP_BASE_API}/em/admin/InfoPublicity/downLoadById?id=${id}`
      }
    }
  }
</script>

<style scoped lang="scss">
  .news-page {
    padding-bottom: 40px;
    .safe-area-banner {
      background: url("~@/assets/images/news_banner.jpg") center;
    }
    .notice-wrapper {
      margin-top: 10px;
      .notice-item {
        height: 56px;
        color: #333333;
        .file-name {
          flex: 1;
        }
        .download-link {
          margin-left: 30px;
          color: $color-primary;
        }
      }
    }
    .pagination {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }
</style>

<style lang="scss">
  .news-page {
    .el-pagination.is-background {
      .el-pager li, .btn-prev, .btn-next {
        font-weight: normal;
        background-color: transparent;
        border: 1px solid $color-border;
        &:hover {
          color: $color-primary;
          border-color: $color-primary;
        }
      }
      .btn-prev:disabled, .btn-next:disabled {
        &:hover {
          border: 1px solid #E1E2E6;
          color: #C0C4CC;
        }
      }
    }
  }
</style>
